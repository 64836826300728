import React, {Suspense, useEffect} from "react";
import { RouterProvider } from "react-router-dom";
import router from "./router";
import Loader from "./components/common/Loader";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <Suspense fallback={<Loader />}>
      <ToastContainer/>
      <RouterProvider router={router} />
    </Suspense>
  );
}

export default App;
