import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import CompanyApi from "../../api/repositories/companies";
import {handleApiError} from "../../helpers/handleApiError";

const initialState = {
  isLoading: false,
};

export const createCompany = createAsyncThunk(
    "company/createCompany",
    async (payload) => {
      try {
        const response = await CompanyApi.create_company(payload);

        if (response.status === 200) {
          localStorage.setItem('company_id', response.data.Company_id)
          localStorage.setItem('company_name', payload)
        }

        return response.data;
      } catch (error) {
        handleApiError(error)
      }
    },
);

export const removeCompany = createAsyncThunk(
    "company/removeCompany",
    async () => {
      try {
        const response = await CompanyApi.remove_company();

        if (response.status === 200) {
          localStorage.removeItem('company_id')
          localStorage.removeItem('company_name')
        }

        return response.data;

      } catch (error) {
        handleApiError(error)
      }
    },
);

export const changeCompany = createAsyncThunk(
    "company/changeCompany",
    async (payload) => {
      try {
        const response = await CompanyApi.change_company(payload);
        return response.data;

      } catch (error) {
        handleApiError(error)
      }
    },
);

const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
        .addCase(changeCompany.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(changeCompany.fulfilled, (state) => {
          state.isLoading = false;
        })
    builder
        .addCase(removeCompany.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(removeCompany.fulfilled, (state) => {
          state.isLoading = false
        });
    builder
        .addCase(createCompany.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(createCompany.fulfilled, (state) => {
          state.isLoading = false
        });
  },
});

export default companySlice.reducer;