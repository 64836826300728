import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Products from "../../api/repositories/products";
import {handleApiError} from "../../helpers/handleApiError";

const initialState = {
  tag: {},
  isLoading: false,
};

export const addTag = createAsyncThunk(
    "products/addTag",

    async (payload) => {
      try {
        const response = await Products.add_tag_product(payload);
        return response.data;
      } catch (error) {
        handleApiError(error)
      }
    },
);

export const deleteTag = createAsyncThunk(
    "products/deleteTag",

    async (payload) => {
      try {
        const response = await Products.delete_tag_product(payload);
        return response.data;
      } catch (error) {
        handleApiError(error)
      }
    },
);


const tagsSlice = createSlice({
  name: "tags",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
        .addCase(addTag.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(addTag.fulfilled, (state, { payload }) => {
          state.isLoading = false;
          state.tag = payload;
        });
    builder
        .addCase(deleteTag.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(deleteTag.fulfilled, (state, { payload }) => {
          state.isLoading = false;
          state.tag = payload;
        });
  },
});

export default tagsSlice.reducer;
