import { Link } from "@mui/material";
interface Props { }

interface Props {
  label?: string,
  to: string,
  align?: "center" | "left" | "right"
  icon?: string
}

const LinkUI = ({ label, to, align, icon }: Props) => {
  return (
    <>
      {label && <Link href={to} color="#FF8800" sx={{ textDecoration: "none", fontSize: "14px", fontWeight: "500", textAlign: align }}>{label}</Link >}
      {
        icon &&
        <Link href={to}>
          <img src={icon} alt={label} />
        </Link>
      }
    </>

  )
}

export default LinkUI