import { Avatar, Box, Button, ClickAwayListener, Divider, FormControl, List, ListItem, ListItemButton, ListItemIcon, MenuItem, Select, Typography } from "@mui/material";
import { ButtonUI, CustomTooltip } from "../../ui";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { CreateNewCompany } from "../Modals";
import IconUI from "../../ui/icon";
import { Link } from "react-router-dom";
import { ModalOverlay } from "../../../helpers/ModalOverlayClick";
import { auth } from "../../../app/slices/auth";
import { changeCompany } from "../../../app/slices/company";
import { createPortal } from "react-dom";
import { getDashboard } from "../../../app/slices/dashboard";
import { setKybStatus } from "../../../app/slices/kyb";
import { setMenuItem } from "../../../app/slices/drawer";
import styles from "./styles";

// TODO: пока что только флаг на отображение тогла
interface Props {
  isLangChange: boolean;
  isAuth?: boolean;
  data?: any;
  isLogo?: boolean,
  isProductInfo?: boolean;
}

const Header = ({ isLangChange, data, isLogo: isLogo, isProductInfo }: Props) => {
  const path = window.location.pathname;
  const dispatch = useDispatch();
  const [dataFetch, setDataFetch] = useState(false);

  const { isAuth } = useSelector((state: any) => state.auth);
  const current_company_id = useSelector((state: any) => state.dashboard.dashboard.Current_Company) || null;

  const [qrMenu, setQrMenu] = useState(false);
  const [profileMenu, setProfileMenu] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [langMenu, setLangMenu] = useState(false);
  const [notification, setNotification] = useState(false);

  const [open, setOpen] = useState(false);
  const [openCompany, setOpenCompany] = useState(false);

  const [lang, setLang] = useState('EN');

  const { user, companies } = useSelector((state: any) => state.dashboard);
  const menu_item = useSelector((state: any) => state.drawer.menu_item);
  const company = companies.find((item: any) => item._id === current_company_id);

  const logoKyb = useSelector((state: any) => state.kyb.logo_company);
  const [logoDashboard, setLogoDashboard] = useState('');

  useEffect(() => {
    if (company)
      setLogoDashboard(company.logo_company);
  }, [company]);

  useEffect(() => {
    const token = localStorage.getItem('access_token');
    if (token) {
      dispatch(auth(true));
    }
  }, []);

  useEffect(() => {
    if (dataFetch && isAuth && !isProductInfo) {
      // @ts-ignore
      dispatch(getDashboard());
    }
    setDataFetch(true);
  }, [dataFetch]);


  const handleClose = () => {
    setOpen(false);
    setProfileMenu(false);
    setQrMenu(false);
    setLangMenu(false);
    setNotification(false);
  };

  const handleProfileMenuClose = () => {
    setProfileMenu(false);
  };

  const handleProfileMenupOpen = () => {
    setProfileMenu(true);
    setQrMenu(false);
    setLangMenu(false);
    setNotification(false);
  };


  const handleQrMenuClose = () => {
    setQrMenu(false);
  };

  const handleQrMenuOpen = () => {
    setQrMenu(true);
    setProfileMenu(false);
    setLangMenu(false);
    setNotification(false);
  };

  const handleLangMenuClose = () => {
    setLangMenu(false);
  };

  const handleLangMenuOpen = () => {
    setLangMenu(true);
    setQrMenu(false);
    setProfileMenu(false);
    setNotification(false);
  };

  const handleNotificationClose = () => {
    setNotification(false);
  };

  const handleNotificationOpen = () => {
    setNotification(true);
    setLangMenu(false);
    setQrMenu(false);
    setProfileMenu(false);
  };

  const logout = () => {
    localStorage.clear();
    dispatch(auth(false));
    window.location.replace('/sign-in');
  };

  const mockNotification: string | any[] = [
    // {
    //   date: '18.07.2024',
    //   text: 'Lorem ipsum dolor $200 sit amet, consectetur adipiscing elit.',
    //   isRead: false,
    //   type: 'info',
    //   id: 1
    // },
    // {
    //   date: '18.07.2024',
    //   text: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem",
    //   isRead: false,
    //   type: 'warning',
    //   id: 2
    // },
    // {
    //   date: '18.07.2024',
    //   text: 'Excepteur sint occaecat 5,000 QR codes cupidatat non.',
    //   isRead: true,
    //   type: 'check',
    //   id: 3
    // }
  ];

  const handleChangeCompany = async (company_id: string) => {
    try {
      if (company_id !== current_company_id) {
        // @ts-ignore
        await dispatch(changeCompany({ company_id }))
          .finally(() => {
            // @ts-ignore
            dispatch(getDashboard());
          });
      }
    } catch (err: any) {

    }
  };

  // const mockNotification: any = []
  // TODO: решить проблему с прелоадером хидера
  return (
    <>
      {/* {isLoading && !isAuth && <div>isLoading</div>} */}
      {isAuth && path.includes('/home') ? (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: "112px", backgroundColor: "#fbfbfb" }}>

          {
            isLogo ?
              <Box sx={{ padding: "0 32px" }}>
                <Link to={'/home/dashboard'} onClick={() => dispatch(setMenuItem('/dashboard'))}>
                  <img src={'/assets/logo.svg'} alt={'logo'}
                    style={{ cursor: 'pointer' }}
                  />
                </Link>
              </Box>
              :
              <Box sx={{ padding: "0 32px" }}>
                {company ?
                  <ClickAwayListener onClickAway={() => setOpenCompany(false)}>
                    <div>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: "16px" }}>

                        <CustomTooltip
                          open={openCompany}
                          onClose={() => setOpenCompany(false)}
                          title={
                            <Box sx={{ position: 'relative', transform: "translate(50px, 8px)" }}>
                              <Box sx={{
                                width: "24px",
                                height: "13px",
                                backgroundColor: "#ffffff",
                                clipPath: "polygon(0 0, 24px 0, 12px 12px)",
                                transform: "rotate(180deg)",
                                position: 'absolute',
                                left: "44px",
                                top: "-12px"
                              }}>

                              </Box>
                              <Box sx={{
                                boxShadow: "0px 0px 16px 0px #E5E5E5",
                                backgroundColor: '#FFFFFF',
                                borderRadius: "10px",
                                width: '227px',
                                color: "#2D2D2D",
                                padding: "0",
                              }}>
                                <List>
                                  {(companies || [])?.map((item: any, index: number) =>
                                    <ListItem disablePadding key={index}>
                                      <Link
                                          to={'/home/dashboard'}
                                          style={{
                                            width: '100%',
                                            outline:'none',
                                            textDecoration: 'none'
                                          }}
                                      >
                                        <ListItemButton
                                          disableRipple
                                          sx={styles.tooltip_item}
                                          onClick={() => {
                                            handleChangeCompany(item._id)}
                                          }
                                        >
                                            <Typography variant="subtitle1" sx={{ fontWeight: 400, color: "#2D2D2D" }}>{item.company_name}</Typography>
                                            <ListItemIcon sx={{ minWidth: "24px" }}>
                                              <IconUI name={item === company ? "radioCheck" : "radioNoCheck"} />
                                            </ListItemIcon>
                                        </ListItemButton>
                                      </Link>
                                    </ListItem>
                                  )}

                                  <ListItem disablePadding>
                                    <ListItemButton
                                      disableRipple
                                      sx={styles.tooltip_add}
                                      onClick={() => {
                                        setOpenCompany(false);
                                        setOpenModal(true);
                                      }}
                                    >
                                      <ListItemIcon sx={{ minWidth: "24px" }}>
                                        <IconUI name="circle_plus" />
                                      </ListItemIcon>
                                      <Typography variant="subtitle1" sx={{ fontWeight: 400, color: "#2D2D2D" }}>Add company</Typography>
                                    </ListItemButton>
                                  </ListItem>
                                </List>
                              </Box>
                            </Box>
                          }
                        >
                          <Button
                            onClick={() => setOpenCompany(true)}
                            disableRipple
                            sx={{
                              textTransform: 'none',
                              minWidth: "100px",
                              '&:hover': {
                                backgroundColor: 'inherit',
                                boxShadow: 'none',
                              },
                            }}
                          >
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: "8px" }}>
                              <Box sx={{ maxWidth: '180px', overflow: 'hidden' }}>
                                {company && (logoKyb || logoDashboard) ? (
                                  <img
                                    src={`https://app.iqtag.io/${menu_item === '/company' ? logoKyb : logoDashboard}`}
                                    alt={'logo'}
                                    style={{ cursor: 'pointer', height: '40px', maxWidth: '100%' }}
                                  />
                                ) : (
                                  <Typography
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      gap: '4px',
                                      fontSize: '24px',
                                      fontWeight: 600,
                                      color: '#0E0E0E',
                                      maxWidth: '170px',
                                      whiteSpace: 'nowrap',
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                    }}
                                  >
                                    {company.company_name}
                                  </Typography>
                                )}
                              </Box>
                              <Box sx={openCompany ? { transform: 'rotate(180deg)' } : { transform: 'rotate(0deg)' }}>
                                <IconUI name="icon-down" size={8} />
                              </Box>
                            </Box>
                          </Button>

                        </CustomTooltip>
                      </Box>
                    </div>
                  </ClickAwayListener>
                  : <ButtonUI text="Create campany" type="outline" onClick={() => setOpenModal(true)} />
                }
              </Box>
          }

          <Box>
            <Box
              sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: "32px", padding: "32px" }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: "16px" }}>

                  <CustomTooltip
                    open={qrMenu || open}
                    onClose={handleQrMenuClose}

                    title={
                      <Box sx={{ position: 'relative', transform: "translate(-40px, 52px)" }}>
                        <Box sx={{
                          width: "24px",
                          height: "13px",
                          backgroundColor: "#ffffff",
                          clipPath: "polygon(0 0, 24px 0, 12px 12px)",
                          transform: "rotate(180deg)",
                          position: 'absolute',
                          right: "44px",
                          top: "-12px"
                        }}>

                        </Box>
                        <Box sx={{
                          boxShadow: "0px 0px 16px 0px #E5E5E5",
                          backgroundColor: '#FFFFFF',
                          borderRadius: "10px",
                          width: '227px',
                          color: "#2D2D2D",
                          padding: "0",
                        }}>
                          <List >
                            <Link to="/home/top-up" style={{ textDecoration: 'none' }} onClick={() => dispatch(setMenuItem('/top-up'))}>
                              <ListItem disablePadding>
                                <ListItemButton
                                  disableRipple
                                  sx={{
                                    textDecoration: 'none',
                                    padding: "8px 16px",
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: "16px"
                                  }}
                                  onClick={handleQrMenuClose}
                                >
                                  <ListItemIcon sx={{ minWidth: "24px" }}>
                                    <IconUI name="topup" />
                                  </ListItemIcon>
                                  <Typography variant="subtitle1" sx={{ fontWeight: 500, color: "#2D2D2D" }}>Top Up QR Balance</Typography>
                                </ListItemButton>

                              </ListItem>
                            </Link>
                          </List>
                        </Box>
                      </Box>

                    }
                  >

                    <Button
                      onClick={handleQrMenuOpen}
                      disableRipple
                      sx={{ textTransform: 'none' }}
                    >

                      <Box sx={{ display: 'flex', alignItems: 'center', gap: "16px", width: '100%' }}>
                        <Box sx={{ flexGrow: 1 }}>
                          <Typography variant="subtitle1" sx={{ fontWeight: 500, textAlign: 'right', color: "#2D2D2D" }}>{user.qr_balance}</Typography>
                          <Typography variant="subtitle2" sx={{ textAlign: 'right', color: "#5F5F5F" }}>QR Balance</Typography>
                        </Box>
                        <Box sx={{
                          backgroundColor: '#000', borderRadius: '50px', padding: "12px", display: 'flex', justifyContent: 'center', alignItems: 'center',
                        }}>
                          <IconUI name="qrcode" />
                        </Box>
                        <Box sx={qrMenu ? { transform: 'rotate(180deg)' } : { transform: 'rotate(0deg)' }}>
                          <IconUI name="icon-down" size={8} />
                        </Box>

                      </Box>
                    </Button>

                  </CustomTooltip>

                  <CustomTooltip
                    open={profileMenu || open}
                    onClose={handleProfileMenuClose}

                    title={
                      <Box sx={{ position: 'relative', transform: "translate(0px, 52px)" }}>
                        <Box sx={{
                          width: "24px",
                          height: "13px",
                          backgroundColor: "#ffffff",
                          clipPath: "polygon(0 0, 24px 0, 12px 12px)",
                          transform: "rotate(180deg)",
                          position: 'absolute',
                          right: "32px",
                          top: "-12px"
                        }}></Box>
                        <Box sx={{
                          boxShadow: "0px 0px 16px 0px #E5E5E5",
                          backgroundColor: '#FFFFFF',
                          borderRadius: "10px",
                          width: '227px',
                          color: "#2D2D2D",
                          boxSizing: "border-box",
                        }}>
                          <List>

                            <Link to="/home/profile" style={{ textDecoration: 'none' }} onClick={() => dispatch(setMenuItem('/profile'))}>

                              <ListItem disablePadding>
                                <ListItemButton
                                  disableRipple
                                  sx={{
                                    textDecoration: 'none',
                                    padding: "8px 16px",
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: "16px"
                                  }}
                                  onClick={handleProfileMenuClose}
                                >
                                  <ListItemIcon sx={{ minWidth: "24px" }}>
                                    <IconUI name="human" />
                                  </ListItemIcon>
                                  <Typography variant="subtitle1" sx={{ fontWeight: 500, color: "#2D2D2D" }}>Profile</Typography>
                                </ListItemButton>

                              </ListItem>
                            </Link>

                            <Link to="/home/profile/password" style={{ textDecoration: 'none' }}>
                              <ListItem disablePadding>

                                <ListItemButton
                                  disableRipple
                                  sx={{
                                    textDecoration: 'none',
                                    padding: "8px 16px",
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: "16px"
                                  }}
                                  onClick={handleProfileMenuClose}
                                >
                                  <ListItemIcon sx={{ minWidth: "24px" }}>
                                    <IconUI name="key" />
                                  </ListItemIcon>
                                  <Typography variant="subtitle1" sx={{ fontWeight: 500, color: "#2D2D2D" }}>Password</Typography>
                                </ListItemButton>
                              </ListItem>
                            </Link>

                            <Divider flexItem sx={{ margin: "8px 0" }} />

                            <ListItem disablePadding>
                              <ListItemButton
                                disableRipple
                                sx={{
                                  textDecoration: 'none',
                                  padding: "8px 16px",
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: "16px"
                                }}
                                onClick={() => logout()}
                              >
                                <ListItemIcon sx={{ minWidth: "24px" }}>
                                  <IconUI name="logout" />
                                </ListItemIcon>
                                <Typography variant="subtitle1" sx={{ fontWeight: 500, color: "#2D2D2D" }}>Logout</Typography>
                              </ListItemButton>
                            </ListItem>
                          </List>

                        </Box>
                      </Box>

                    }
                  >
                    <Button
                      onClick={handleProfileMenupOpen}
                      disableRipple
                      sx={{ textTransform: 'none' }}
                    >

                      <Box sx={{ display: 'flex', alignItems: 'center', gap: "8px" }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: "16px", width: '100%' }}>
                          <Box sx={{ flexGrow: 1 }}>
                            <Typography variant="subtitle1" sx={{ fontWeight: 500, textAlign: 'right', color: "#2D2D2D" }}>
                              {`${user.first_name} ${user.second_name}`}
                            </Typography>
                            <Typography variant="subtitle2" sx={{ textAlign: 'right', color: "#5F5F5F" }}>{user.email}</Typography>
                          </Box>
                          <Avatar src={user.avatar_url ? `https://app.iqtag.io${user.avatar_url}` : ''} />
                        </Box>
                        <Box sx={profileMenu ? { transform: 'rotate(180deg)' } : { transform: 'rotate(0deg)' }}>
                          <IconUI name="icon-down" size={8} />
                        </Box>
                      </Box>

                    </Button>
                  </CustomTooltip>

                  {/*<CustomTooltip*/}
                  {/*  open={notification || open}*/}
                  {/*  onClose={handleNotificationClose}*/}
                  {/*  title={*/}
                  {/*    <Box sx={{ position: 'relative', transform: "translate(-140px, 62px)" }}>*/}
                  {/*      <Box sx={{*/}
                  {/*        width: "24px",*/}
                  {/*        height: "13px",*/}
                  {/*        backgroundColor: "#ffffff",*/}
                  {/*        clipPath: "polygon(0 0, 24px 0, 12px 12px)",*/}
                  {/*        transform: "rotate(180deg)",*/}
                  {/*        position: 'absolute',*/}
                  {/*        right: "-6px",*/}
                  {/*        top: "-12px",*/}
                  {/*      }}>*/}

                  {/*      </Box>*/}
                  {/*      <Box sx={{*/}
                  {/*        boxShadow: "0px 0px 16px 0px #E5E5E5",*/}
                  {/*        backgroundColor: '#FFFFFF',*/}
                  {/*        borderRadius: "10px",*/}
                  {/*        width: '410px',*/}
                  {/*        color: "#2D2D2D",*/}
                  {/*        padding: "0",*/}
                  {/*      }}>*/}
                  {/*        <List >*/}
                  {/*          { // заменить any на тип*/}
                  {/*            mockNotification.length > 0*/}
                  {/*              ? (*/}
                  {/*                <>*/}
                  {/*                  {*/}
                  {/*                    (mockNotification.length > 0 ? mockNotification : []).map((item: any, index: number) => (*/}
                  {/*                      <Box key={index}>*/}
                  {/*                        <ListItem disablePadding key={item.id}>*/}
                  {/*                          <Box sx={{ display: 'flex', flexDirection: 'column', padding: "16px" }} >*/}
                  {/*                            <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row', gap: "16px" }}>*/}
                  {/*                              <Box sx={{ width: '24px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>*/}
                  {/*                                <Box sx={{ width: "8px", height: "8px", backgroundColor: item.isRead ? "#FFF" : "#FF8800", borderRadius: "50%" }}></Box>*/}
                  {/*                              </Box>*/}
                  {/*                              <Typography variant="subtitle2" sx={{ color: "#5F5F5F" }}>{item.date}</Typography>*/}
                  {/*                            </Box>*/}
                  {/*                            <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row', gap: "16px" }}>*/}
                  {/*                              <IconUI name={`${item.type}_color`} size={24} />*/}
                  {/*                              <Typography variant="subtitle2" sx={{ fontWeight: 500, lineHeight: "24px", letterSpacing: '1%' }}>{item.text}</Typography>*/}
                  {/*                            </Box>*/}
                  {/*                          </Box>*/}
                  {/*                        </ListItem>*/}
                  {/*                        {*/}
                  {/*                          index !== mockNotification.length - 1 && <Divider flexItem sx={{ width: "calc(100% - 32px)", transform: "translateX(16px)", }} />*/}
                  {/*                        }*/}

                  {/*                      </Box>*/}

                  {/*                    ))*/}
                  {/*                  }*/}
                  {/*                  <Box sx={{ display: 'flex', justifyContent: 'center', padding: "16px 0 32px 0" }}>*/}
                  {/*                    <Link to='/home/notifications'>*/}
                  {/*                      <ButtonUI text="View all" type="default" onClick={handleNotificationClose} />*/}
                  {/*                    </Link>*/}
                  {/*                  </Box>*/}
                  {/*                </>*/}

                  {/*              ) : (*/}
                  {/*                <NoData*/}
                  {/*                  icon='no_bell'*/}
                  {/*                  description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. "*/}
                  {/*                  buttonText="View all"*/}
                  {/*                  onClick={handleNotificationClose}*/}
                  {/*                />*/}
                  {/*              )*/}
                  {/*          }*/}
                  {/*        </List>*/}
                  {/*      </Box>*/}
                  {/*    </Box>*/}
                  {/*  }*/}
                  {/*>*/}
                  {/*  <Button*/}
                  {/*    onClick={handleNotificationOpen}*/}
                  {/*    disableRipple*/}
                  {/*    sx={{ textTransform: 'none' }}*/}
                  {/*  >*/}
                  {/*    <Box sx={{ display: 'flex', alignItems: 'center', gap: "8px" }}>*/}
                  {/*      <IconUI name={mockNotification.length > 0 ? 'bell_notif' : 'bell'} size={24} />*/}
                  {/*      <Box sx={notification ? { transform: 'rotate(180deg)' } : { transform: 'rotate(0deg)' }}>*/}
                  {/*        <IconUI name="icon-down" size={8} />*/}
                  {/*      </Box>*/}
                  {/*    </Box>*/}
                  {/*  </Button>*/}
                  {/*</CustomTooltip>*/}

                  <CustomTooltip
                    open={langMenu || open}
                    onClose={handleLangMenuClose}
                    title={
                      <Box sx={{ position: 'relative', transform: "translate(-32px, 52px)" }}>
                        <Box sx={{
                          width: "24px",
                          height: "13px",
                          backgroundColor: "#ffffff",
                          clipPath: "polygon(0 0, 24px 0, 12px 12px)",
                          transform: "rotate(180deg)",
                          position: 'absolute',
                          right: "16px",
                          top: "-12px"
                        }}>

                        </Box>
                        <Box sx={{
                          boxShadow: "0px 0px 16px 0px #E5E5E5",
                          backgroundColor: '#FFFFFF',
                          borderRadius: "10px",
                          width: '109px',
                          color: "#2D2D2D",
                          padding: "0",
                        }}>
                          <List >
                            <ListItem disablePadding>
                              <ListItemButton
                                disableRipple
                                sx={{
                                  textDecoration: 'none',
                                  padding: "8px 16px",
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: "16px"
                                }}
                                onClick={() => {
                                  setLang("EN");
                                  handleClose();
                                }}
                              >
                                <ListItemIcon sx={{ minWidth: "24px" }}>
                                  <IconUI name="UK" />
                                </ListItemIcon>
                                <Typography variant="subtitle1" sx={{ fontWeight: 500, color: "#2D2D2D" }}>EN</Typography>
                              </ListItemButton>
                            </ListItem>
                            {/* 
                    TODO: когда будет использоваться локаль FR
                    <ListItem disablePadding>
                      <ListItemButton
                        disableRipple
                        sx={{
                          textDecoration: 'none',
                          padding: "8px 16px",
                          display: 'flex',
                          alignItems: 'center',
                          gap: "16px"
                        }}
                        onClick={() => {
                          setLang("FR");
                          handleClose()
                        }}
                      >
                        <ListItemIcon sx={{ minWidth: "24px" }}>
                          <IconUI name="FR" />
                        </ListItemIcon>
                        <Typography variant="subtitle1" sx={{ fontWeight: 500, color: "#2D2D2D" }}>FR</Typography>
                      </ListItemButton>
                    </ListItem> */}

                          </List>
                        </Box>
                      </Box >
                    }
                  >
                    <Button
                      onClick={handleLangMenuOpen}
                      disableRipple
                      sx={{ textTransform: 'none' }}
                    >
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: "8px" }}>
                        <Typography variant="subtitle1" sx={{ fontWeight: 400, color: "#2D2D2D" }}>{lang}</Typography>
                        <Box sx={langMenu ? { transform: 'rotate(180deg)' } : { transform: 'rotate(0deg)' }}>
                          <IconUI name="icon-down" size={8} />
                        </Box>
                      </Box>
                    </Button>

                  </CustomTooltip >


                </Box >

              </ClickAwayListener >

            </Box >
          </Box >

        </Box >
      ) : (
        <Box
          sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', }}
          padding={'32px 40px'}
        >
          <img src={'/assets/logo.svg'} alt={'logo'} />
          <FormControl
            variant="outlined"
            size="small"
          >
            <Select
              displayEmpty
              defaultValue="en"
              // onChange={handleChange}
              sx={styles.customSelector}
            >
              <MenuItem value="en">EN</MenuItem>
              <MenuItem value="fr">FR</MenuItem>
            </Select>
          </FormControl>
        </Box >
      )
      }
      {
        openModal && createPortal(
          <ModalOverlay onClose={() => setOpenModal(false)} openModal={openModal}>
            <CreateNewCompany
              openModal={openModal}
              setOpenModal={setOpenModal}
            />
          </ModalOverlay>,
          document.getElementById('portal') as HTMLElement
        )
      }

    </>
  );
};

export default Header;