import instance from '../Service'

export default class Kyb {

  static async company_data(data) {
    return await instance.post('/kyb/company_data', data)
  }
  static async identity_data(data) {
    return await instance.post('/kyb/identity_data', data)
  }
  static async address_data(data) {
    return await instance.post('/kyb/address_data', data)
  }
  static async upload_pdfs(data) {
    return await instance.post('/kyb/upload_pdfs', data)
  }
  static async upload_logo(data) {
    return await instance.post('/kyb/upload_logo', data,
        {headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
  }
  static async delete_logo(){
    return await instance.post('kyb/delete_logo')
  }
  static async get_kyb_status() {
    return await instance.get('/kyb/get_kyb_status')
  }
}