import { Box, Button } from "@mui/material";
import IconUI from "./icon";
import styles from "./styles.module.scss"

interface Props {
  text: string,
  type?: "default" | "icon" | "outline",
  onClick?: () => void,
  width?: string,
  disabled?: boolean
  iconStyles?: object,
  hasBorder?: boolean,
  icon?: string,
  iconSize?: number,
  pulse?: boolean,
}

const ButtonUI = ({ text, onClick, type = 'default', width, disabled = false, iconStyles, hasBorder=true, icon = "arrow_left", iconSize, pulse=false}: Props) => {
  return (
    <>
      {
        type === "default" && <Button
          onClick={onClick}
          disabled={disabled}
          sx={{
            width: width ? width : 'max-content',
            backgroundColor: disabled ? "#D8D8D8" : "#000000",
            color: '#FFFFFF',
          }}
          className={`${styles.pulse} ${pulse ? styles.pulseAnimate : ''}`}
        >
          {text}
        </Button>
      }
      {
        type === "outline" && <Button
          onClick={onClick}
          disabled={disabled}
          sx={{
            borderRadius: "48px",
            padding: "12px 32px",
            backgroundColor: "#transparent",
            border: hasBorder ? "2px solid #000000" : '',
            color: "#000000",
            fontSize: '16px',
            fontWeight: '600',
            width: width ? width : 'max-content',
            textTransform: 'none',
            height: '50px',
            boxSizing: 'border-box',
            ...iconStyles
          }}
        >
          {text}
        </Button>
      }
      {
        type === "icon" &&

        <Box sx={{ position: 'relative', display: 'flex'}}>
          <IconUI name={icon} direction="left" style={iconStyles} size={iconSize} animate={icon === "miniloader" && true}/>
          <Button
            onClick={onClick}
            disabled={disabled}
            sx={{
              borderRadius: "48px",
              padding: "12px 32px",
              backgroundColor: "#transparent",
              border: "none",
              color: "#000000",
              fontSize: '16px',
              fontWeight: '500',
              width: width ? width : 'max-content',
              textTransform: 'none',
              ...(icon !== "arrow_left" && {
                backgroundColor: disabled ? "#D8D8D8" : "#000000",
                border: "2px solid #000000",
              })
            }}
          >
            {text}
          </Button >
        </Box>
      }
    </>
  )
}

export default ButtonUI