import instance from "../Service";

export default class QrPreview {
  static async send_feetback(data) {
    return await instance.post("/qr_preview/send_feedback", data)
  }
  static async get_product_public({product_id, qr_name}){
    return await instance.get(`/qr_preview/get_product?product_id=${product_id}&qr_name=${qr_name}`)
  }
  static async get_product_private({product_id, qr_id}){
    return await instance.get(`/qr_preview/get_product?product_id=${product_id}&code=${qr_id}`)
  }
}
