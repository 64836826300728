import instance from "../Service";

export default class Profile {
  static async upload_avatar(data) {
    return await instance.post("/profile/upload_avatar", data,
        {headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
  }
  static async update_data(data) {
    return instance.post("/profile/update_data", data);
  }
  static async update_password(data) {
    return await instance.post("/profile/update_password", data);
  }
}
