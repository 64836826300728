import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {handleApiError} from "../../helpers/handleApiError";
import QrPreview from "../../api/repositories/qrPreview";

const initialState = {
  productQrData: {
  },
};

export const getQrProductPublic = createAsyncThunk(
    "qrPreview/getProduct/public",
    async (payload) => {
      try{
        const response = await QrPreview.get_product_public(payload)
        return response.data;
      } catch (error){
        handleApiError(error)
      }
    }
)
export const getQrProductPrivate = createAsyncThunk(
  "qrPreview/getProduct/private",
  async (payload) => {
    try{
      const response = await QrPreview.get_product_private(payload)
      return response.data;
    } catch (error){
      handleApiError(error)
    }
  }
)
export const sendFeedback = createAsyncThunk(
    "qrPreview/sendFeedback",
    async (payload) => {
      try{
        const response = await QrPreview.send_feetback(payload)
        return response.data;
      } catch (error){
        handleApiError(error)
      }
    }
)

const qrPreviewSlice = createSlice({
  name: "qrPreview",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
        .addCase(getQrProductPublic.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(getQrProductPublic.fulfilled, (state,{payload}) => {
          state.isLoading = false;
          state.productQrData = payload.Event
        })
    builder
        .addCase(getQrProductPrivate.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(getQrProductPrivate.fulfilled, (state,{payload}) => {
          state.isLoading = false;
          state.productQrData = payload.Event
        })
    builder
        .addCase(sendFeedback.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(sendFeedback.fulfilled, (state) => {
          state.isLoading = false;
        })
  },
});

export default qrPreviewSlice.reducer;
