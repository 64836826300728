  import { Box } from "@mui/material"
import "./styles.css"
const AnimationBg = () => {

  return (
    <Box sx={{
      position: 'fixed',
      width: '100vw',
      height: '100%',
      display: 'flex',
      top: '0',
      bottom: '0',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: -1,
      overflow: 'hidden',

    }}>
      <img src='/assets/bg.svg' alt='background' className='bg rotate' />
    </Box >

  )
}

export default AnimationBg