import { Box, FormControl, FormLabel, Input, InputBase, MenuItem, Select, TextField, Typography } from "@mui/material";
import LinkUI from "./link";
import LabelUI from "./label";
import IconUI from "./icon";

interface Props {
  label?: string,
  link?: { label: string, to: string }
  icon?: string | null
  icon_color?: string
  icon_direction?: string
  onClick?: () => void
  type?: string,
  error?: boolean,
  variant?: 'field' | 'textarea' | 'button' | 'title',
  lines?: number,
  value?: string | null,
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void,
  selectDefaultValue?: any
}


const CustomInputUI = (
  { label,
    variant,
    icon,
    icon_color,
    onClick,
    type,
    icon_direction,
    error,
    lines = 5,
    value,
    onChange
  }: Props) => {
  return (
    <FormControl sx={{ flexGrow: 1 }}>

      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: label ? "0 16px 16px" : 0 }}>
        {label && <LabelUI htmlFor="input-with-icon-adornment" text={label} />}
        {
          <Typography variant="caption" color="red" sx={{ fontSize: "14px", fontWeight: "400", color: "#5F5F5F", textTransform: 'uppercase' }} >
            {`[${variant}]`}
          </Typography>
        }
      </Box>

      <FormControl sx={{ position: "relative" }}>
        {
          variant !== 'textarea' &&
          (
            <>
              <InputBase sx={{
                border: error ? "1px solid #FF6954" : "1px solid #D8D8D8",
                borderRadius: "10px",
                padding: "8px 16px",
                position: "relative",
                width: "100%",
                pointerEvents: "none"
              }}
                type={type}
                value={value}
                onChange={onChange}
              />

              {icon && <IconUI name={icon} color={icon_color} onClick={onClick} direction={icon_direction} />}
            </>
          )
        }
        {
          variant === 'textarea' && (
            <>
              <TextField
                id="outlined-textarea"

                multiline
                rows={lines}
                sx={{
                  borderRadius: "10px",
                  border: '1px solid #D8D8D8',
                  pointerEvents: "none"
                }}
                value={value}
              />
              <Box sx={{ position: 'absolute', right: 0, top: "24px", }}>
                {icon && <IconUI name={icon} color={icon_color} onClick={onClick} direction={icon_direction} />}
              </Box>

            </>
          )
        }
      </FormControl>
    </FormControl >
  )
}

export default CustomInputUI