import { FormLabel, Typography } from "@mui/material";

interface Props {
  text: string,
  htmlFor?: string,
  align?: "center" | "left" | "right"
  error?: boolean | string
}

const LabelUI = ({ text, htmlFor, align, error }: Props) => {
  return (
    <FormLabel
      htmlFor={htmlFor}
      sx={{ textAlign: align }}
    >
      <Typography variant="caption" sx={{ color: error ? "#FF6954" : "#5F5F5F", fontSize: "14px" }}>{text}</Typography>
    </FormLabel>
  )
}

export default LabelUI