import { Tooltip } from "@mui/material"
import { Children } from "react"
import "./styles.css"

interface Props {
  children?: any
  title?: any
  open?: boolean,
  onClose?: any
}
const CustomTooltip = ({ children, title, open, onClose }: Props) => {

  return (
    <Tooltip
      PopperProps={{
        disablePortal: true,
      }}
      onClose={onClose}
      open={open}
      disableFocusListener
      disableHoverListener
      disableTouchListener
      title={title}
      classes={{
        tooltip: "customTooltip",
      }}
      sx={{
        "& .MuiTooltip-tooltip": {
          backgroundColor: "transparent !important",
          padding: "0 !important",
          margin: "0 !important",
        },
        "& .MuiTooltip-touch": {
          backgroundColor: "transparent !important",
          padding: "0 !important",
          margin: "0 !important",
        }
      }}
    >
      {children}
    </Tooltip >
  )
}
export default CustomTooltip