import { Box, Typography } from "@mui/material"
import styles from "./styles"
import { ButtonUI, InputUI } from "../../../ui"
import { data } from "./constants"
import { useState } from "react"
import { useNavigate } from "react-router"
import {useDispatch} from "react-redux";
import {createCompany} from "../../../../app/slices/company";
import {getDashboard} from "../../../../app/slices/dashboard";
import {setKybStatus} from "../../../../app/slices/kyb";
interface Props {
  openModal: boolean,
  setOpenModal: (openModal: boolean) => void
}
const CreateNewCompany = ({ openModal, setOpenModal }: Props) => {
  const dispatch = useDispatch()
  const { title, subtitle } = data
  const [error, setError] = useState('')
  const [companyName, setCompanyName] = useState('')
  const navigate = useNavigate()

  const submitHandler = async (company_name: string) => {
    try {
      // @ts-ignore
      await dispatch(createCompany({company_name}))
          // @ts-ignore
          .finally(() => {dispatch(getDashboard()); dispatch(setKybStatus())})

      setOpenModal(false)
      navigate('/home/company')
    } catch (e: any) {
      setError(e.response.data.detail)
    }
  }

  return (
      <Box
        sx={styles.container}
        onClick={(e) => {e.stopPropagation()}}
      >

        <Box sx={styles.header}>
          <Typography variant="h6">{title}</Typography>
          <img src='/assets/icons/modal_close.svg'
               onClick={() => setOpenModal(false)}
               style={{cursor: 'pointer'}}/>
        </Box>

        <Typography variant="subtitle2" sx={styles.subtitle}>
          {subtitle}
        </Typography>

        <Box sx={{ display: "flex", padding: "0 32px" }}>
          <InputUI
            label="Company name"
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
          />
        </Box>


        <Box sx={styles.buttons}>
          <ButtonUI
            type="outline"
            text="Cancel"
            onClick={() => setOpenModal(false)}
            width="114px"
          />

          <ButtonUI
            type="default"
            text="Create"
            onClick={() => submitHandler(companyName)}
            width="112px"
          />
        </Box>

      </Box>
  )
}

export default CreateNewCompany