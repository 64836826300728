import instance from '../Service'

export default class CompanyAPI {
  static async create_company(data) {
    return await instance.post('/company/create_company', data)
  }
  static async remove_company() {
    return await instance.post('/company/remove_company')
  }
  static async change_company(data)  {
    return await instance.post('/company/change_company',data)
  }
}