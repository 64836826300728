import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  menu_item: '/dashboard',
  isMenuOpen: JSON.parse(localStorage.getItem('isMenuOpen')) !== null ? JSON.parse(localStorage.getItem('isMenuOpen')) : true,
  isMobileOpen: false
}

const drawerSlice = createSlice({
  name: "drawer",
  initialState,
  reducers: {
    setMenuItem: (state, action) => {
      state.menu_item = action.payload
    },
    setIsMobileOpen: (state, action) => {
      state.isMobileOpen = action.payload
    },
    setMenuOpen: (state, action) => {
      state.isMenuOpen = action.payload;
      localStorage.setItem('isMenuOpen', JSON.stringify(action.payload));
    },
  }
})

export const { setMenuItem, setIsMobileOpen, setMenuOpen} = drawerSlice.actions
export default drawerSlice.reducer