import instance from "../Service";

export default class Payment {
  static async buy_starter_pack() {
    return await instance.get("/pay/buy_starter_pack")
  }
  static async buy_growth_pack(){
    return await instance.get(`/pay/buy_growth_pack`)
  }
  static async buy_enterprise_pack(){
    return await instance.get(`/pay/buy_enterprise_pack`)
  }
}
