import {Box} from "@mui/material";
import {ReactNode, useEffect} from "react";

interface Props {
  onClose: () => void,
  openModal: boolean,
  children: ReactNode
}

export const ModalOverlay = ({onClose, openModal, children} : Props) => {

  const handleOverlayClick = (e: React.MouseEvent) => {
    onClose();
  };

  useEffect(() => {
    if (openModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }

    return () => {
      document.body.style.overflow = "";
    };
  }, [openModal]);

  if (!openModal) return null;

  return (
      <Box
          sx={{
            height: '100vh',
            width: '100vw',
            backgroundColor: "rgba(255, 255, 255, 0.64)",
            position: 'fixed',
            top: 0,
            zIndex: 100,
            backdropFilter: 'blur(24px)',

            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',

            overflow: 'hidden',
          }}
          onClick={handleOverlayClick}
      >
        {children}
      </Box>
  );
};