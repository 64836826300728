const styles = {
  customSelector: {
    boxShadow: "none",
    padding: '0 !important',
    ".MuiOutlinedInput-notchedOutline": { border: 0 },
    "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
    {
      border: 0,
    },
    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
    {
      border: 0,
    },
  },
  customTooltip: {
    margin: 0,
    padding: 0
  },
  tooltip_item: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  tooltip_add: {
    paddingTop: '16px',
    display: 'flex',
    gap: '1rem'
  },
  lastItem: {
    paddingBottom: '8px',
    borderBottom: '1px solid #F5F5F5'
  }
} as const

export default styles