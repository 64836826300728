export const icons = [
  {
    tag: <img src={'/assets/icons/inst.svg'} alt={'instagram'}/>,
    link: 'https://www.instagram.com/iqtag_io'
  },
  {
    tag: <img src={'/assets/icons/x.svg'} alt={'twitter'} />,
    link:  'https://x.com/iqtag_io'
  }
  // <img src={'/assets/icons/telegram.svg'} alt={'telegram'} />,
  // <img src={'/assets/icons/facebook.svg'} alt={'facebook'} />,

]

export const icon_white = [
  {
    tag: <img src={'/assets/icons/inst_white.svg'} alt={'instagram'} />,
    link:  'https://www.instagram.com/iqtag_io'
  },
  {
    tag:<img src={'/assets/icons/x_white.svg'} alt={'twitter'} />,
    link:  'https://x.com/iqtag_io'
  }
  // <img src={'/assets/icons/telegram_white.svg'} alt={'telegram'} />,
  // <img src={'/assets/icons/facebook_white.svg'} alt={'facebook'} />,
]

export const info = [
  { icon: "location", text: "Vlkova 532/8, 130 00 Praha, Czechia" },
  { icon: "mail", text: "support@iqtag.io" },
  // { icon: "phone", text: "+380 096 123 45 67" },
]