const styles = {
  link: {
    color: "#FFF",
    fontSize: "16px",
    fontWeight: "400",
    textDecoration: "none"
  },
  not_auth_link: {
    fontSize: "14px",
    fontWeight: "400",
    color: "#000",
    textDecoration: "underline",
    '@media (max-width: 624px)': {

    }
  },

  not_auth_footer: {
    padding: "40px 150px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "0",

    "@media (max-width: 624px)": {
      padding: "32px",
      flexDirection: "column",
      gap: '32px',

    }
  },

  not_auth_footer_links: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "32px",

    "@media (max-width: 624px)": {
      flexDirection: "column",
    }
  },
  not_auth_icons: {
    display: "flex",
    flexDirection: "row"
  },

  not_auth_icon: {
    display: 'inline-flex',
    padding: '0 8px',
  },

  not_auth_footer_underline_links: {
    display: "flex",
    justifyContent: "center",
    gap: "32px",
    flexDirection: "row"
  }
}

export default styles