import instance from "../Service";

export default class Referral {
  static async get_referal_data() {
    return await instance.get("/referral/get_referal_data");
  }
  static async get_history_data() {
    return await instance.get("/referral/get_history_data");
  }
  static async push_referal_data(data) {
    return await instance.post("referral/withdrawal_push", data);
  }
}
