import {Dispatch, SetStateAction, useState} from "react";
import styles from "./styles";
import {
  Box,
  Typography,
} from "@mui/material";
import { ButtonUI, InputUI } from "../../../ui";
import {getProduct} from "../../../../app/slices/products";
import {addCustomField} from "../../../../app/slices/field";
import {Form, Formik} from "formik";
import {useDispatch} from "react-redux";

interface Props {
  setOpenModal: (openModal: boolean) => void;
  productId: string | undefined,
  setUpdateFields: Dispatch<SetStateAction<boolean>>,
  index: string
}

const AddNewField = ({
  setOpenModal,
  productId,
  setUpdateFields,
  index
}: Props) => {
  const [type, setType] = useState("field");
  const selectData = [
    { value: "field", label: "[FIELD]" },
    { value: "button", label: "[BUTTON]" },
    { value: "textarea", label: "[TEXTAREA]" },
  ];
  const dispatch = useDispatch();

  const textarea = false;

  const submitHandler = async (values: any) => {
    try {
      console.log(values)
      // @ts-ignore
      dispatch(addCustomField({ productId, ...values , index}))
          .then(() => setOpenModal(false))
          .finally(() => {
            // @ts-ignore
            dispatch(getProduct(productId))
            setUpdateFields(true)
          })

    } catch (e: any) {
      console.log(e);
    }
  };


  return (
    <Formik
      initialValues={{
        type: "field",
        title: "",
        description: ""
      }}
      onSubmit={submitHandler}
    >
    {({ values, handleSubmit, setFieldValue }) => (
      <Form>
        <Box
            sx={textarea ? styles.containerTextarea : styles.container}
            onClick={(e) => {e.stopPropagation()}}
        >
          <Box sx={styles.header}>
            <Typography variant="h6">Add new field</Typography>
            <img
              src="/assets/icons/modal_close.svg"
              onClick={() => setOpenModal(false)}
              alt={''}
              style={{cursor: 'pointer'}}
            />
          </Box>

          <Box sx={{ display: "flex", padding: "0 32px" }}>
            <InputUI
              label="Type"
              select
              selectData={selectData}
              value={values.type}
              selectHandleChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setFieldValue('type',event.target.value)}
            />
          </Box>

          <Box sx={{ display: "flex", padding: "0 32px" }}>
            <InputUI label="Title" value={values.title} onChange={
              (event: React.ChangeEvent<HTMLInputElement>) =>
                setFieldValue('title',event.target.value)}
            />
          </Box>

          {type === "textarea" ? (
            <Box
              sx={{ display: "flex", padding: "0 32px" }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setFieldValue('description',event.target.value)}
            >
              <InputUI label="Description" value={values.description} multiline />
            </Box>
          ) : (
            <Box
              sx={{ display: "flex", padding: "0 32px" }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setFieldValue('description',event.target.value)}
            >
              <InputUI label="Content" value={values.description}/>
            </Box>
          )}

          <Box sx={styles.buttons}>
            <ButtonUI
              type="outline"
              text="Cancel"
              onClick={() => setOpenModal(false)}
              width="114px"
            />

            <ButtonUI
              type="default"
              text="Add"
              onClick={() => {
                handleSubmit()
              }}
              width="114px"
            />
          </Box>
        </Box>
      </Form>
    )}
    </Formik>
  );
};

export default AddNewField;
