import { configureStore } from "@reduxjs/toolkit";
import dashboardSlice from "./slices/dashboard";
import kybSlice from "./slices/kyb";
import productsSlice from "./slices/products";
import drawerSlice from "./slices/drawer";
import qrsSlice from "./slices/qr";
import customFieldSlice from './slices/field'
import addPhotoSlice from './slices/photo'
import tagsSlice from './slices/tags'
import profileSlice from './slices/profile'
import authSlice from './slices/auth'
import referralSlice from './slices/referral'
import billsSlice from './slices/bills'
import qrPreviewSlice from './slices/qrPreview'
import paymentSlice from './slices/payment'
import analyticsSlice from './slices/analytics'

const combinedReducers = {
  dashboard: dashboardSlice,
  kyb: kybSlice,
  products: productsSlice,
  drawer: drawerSlice,
  qr: qrsSlice,
  field: customFieldSlice,
  photo: addPhotoSlice,
  tag: tagsSlice,
  profile: profileSlice,
  auth: authSlice,
  referral: referralSlice,
  qrPreview: qrPreviewSlice,
  bills: billsSlice,
  payment: paymentSlice,
  analytics: analyticsSlice,
};

export const store = configureStore({
  reducer: combinedReducers,
});
