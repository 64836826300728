import { Box, Button, Link, List, ListItem, ListItemButton, ListItemIcon, Typography } from "@mui/material";
import { CustomTooltip, LabelUI, LinkUI } from "../../ui";
import { useState } from "react";
import IconUI from "../../ui/icon";
import styles from "./styles";
import { icons, info, icon_white } from "./constants"

interface Props {
  isAuth?: boolean
}

const Footer = ({ isAuth = false }: Props) => {

  // TODO: вынести в родительские компоненты
  const [open, setOpen] = useState(false);
  const [lang, setLang] = useState('EN');
  const [langMenu, setLangMenu] = useState(false);
  const screenWidth = window.innerWidth;
  const handleLangMenuClose = () => {
    setLangMenu(false);
  };

  const handleLangMenuOpen = () => {
    setLangMenu(true);
  };

  const handleClose = () => {
    setOpen(false);
    setLangMenu(false);
  };

  return (
    <>
      {
        isAuth ? (
          <Box sx={{
            padding: "40px 150px",
            backgroundColor: "#000",
            display: "flex",
            flexDirection: "column",
            gap: "64px",
          }}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <img src={'/assets/logo_white.svg'} alt={'logo'} />
              <Box sx={{ display: "flex", gap: "32px", alignItems: "center" }}>
                <Link href="https://iqtag.io/#how-works" sx={styles.link} >
                  How It Works
                </Link>
                <Link href="https://iqtag.io/#security" sx={styles.link} >
                  Security
                </Link>
                <Link href="https://iqtag.io/#advantages" sx={styles.link} >
                  Advantages
                </Link>
                <Link href="https://iqtag.io/#contacts" sx={styles.link} >
                  Contacts
                </Link>
                <CustomTooltip
                  open={langMenu || open}
                  onClose={handleLangMenuClose}
                  title={
                    <Box sx={{ position: 'relative', transform: "translate(-32px, 52px)" }}>
                      <Box sx={{
                        width: "24px",
                        height: "13px",
                        backgroundColor: "#ffffff",
                        clipPath: "polygon(0 0, 24px 0, 12px 12px)",
                        transform: "rotate(180deg)",
                        position: 'absolute',
                        right: "16px",
                        top: "-12px"
                      }}>

                      </Box>
                      <Box sx={{
                        boxShadow: "0px 0px 16px 0px #E5E5E5",
                        backgroundColor: '#FFFFFF',
                        borderRadius: "10px",
                        width: '109px',
                        color: "#2D2D2D",
                        padding: "0",
                      }}>
                        <List >
                          <ListItem disablePadding>
                            <ListItemButton
                              disableRipple
                              sx={{
                                textDecoration: 'none',
                                padding: "8px 16px",
                                display: 'flex',
                                alignItems: 'center',
                                gap: "16px"
                              }}
                              onClick={() => {
                                setLang("EN");
                                handleClose()
                              }}
                            >
                              <ListItemIcon sx={{ minWidth: "24px" }}>
                                <IconUI name="UK" />
                              </ListItemIcon>
                              <Typography variant="subtitle1" sx={{ fontWeight: 500, color: "#2D2D2D" }}>EN</Typography>
                            </ListItemButton>
                          </ListItem>
                          {/* 
                              TODO: когда будет использоваться локаль FR
                              <ListItem disablePadding>
                                <ListItemButton
                                  disableRipple
                                  sx={{
                                    textDecoration: 'none',
                                    padding: "8px 16px",
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: "16px"
                                  }}
                                  onClick={() => {
                                    setLang("FR");
                                    handleClose()
                                  }}
                                >
                                  <ListItemIcon sx={{ minWidth: "24px" }}>
                                    <IconUI name="FR" />
                                  </ListItemIcon>
                                  <Typography variant="subtitle1" sx={{ fontWeight: 500, color: "#2D2D2D" }}>FR</Typography>
                                </ListItemButton>
                              </ListItem> */}

                        </List>
                      </Box>
                    </Box>
                  }
                >
                  <Button
                    onClick={handleLangMenuOpen}
                    disableRipple
                    sx={{ textTransform: 'none' }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: "8px" }}>
                      <Typography variant="subtitle1" sx={{ fontWeight: 400, color: "#FFF" }}>{lang}</Typography>
                      <Box sx={langMenu ? { transform: 'rotate(180deg)' } : { transform: 'rotate(0deg)' }}>
                        <IconUI name="icon_down_white" size={8} />
                      </Box>
                    </Box>
                  </Button>

                </CustomTooltip>
              </Box>
            </Box>

            <Box sx={{ display: "flex", gap: "32px", alignItems: "center", justifyContent: "space-between" }}>
              <Box sx={{ display: "flex", gap: "32px", alignItems: "center" }}>
                {
                  info && info.map((item, index) => {
                    return (
                      <Box sx={{ display: "flex", gap: "16px", alignItems: "center" }}>
                        <IconUI name={item.icon} />
                        <Typography variant="subtitle1" sx={{ fontSize: "16px", fontWeight: "400", color: "#FFFFFF" }}>{item.text}</Typography>
                      </Box>
                    )
                  })
                }
              </Box>
              {/*<Button*/}
              {/*  sx={{*/}
              {/*    color: '#FFF',*/}
              {/*    textTransform: 'none',*/}
              {/*    border: "2px solid #FFFFFF",*/}
              {/*    padding: "12px 32px",*/}
              {/*    borderRadius: "48px",*/}
              {/*  }}*/}
              {/*>*/}
              {/*  Write to us*/}
              {/*</Button>*/}
            </Box>

            <Box sx={{ display: "flex", gap: "32px", flexDirection: "column" }}>
              <Box sx={{ height: "1px", width: "100%", backgroundColor: "#2D2D2D" }} />
              <Box sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center"
              }} m={0} >

                <Typography variant="subtitle1" sx={{ fontSize: "14px", fontWeight: "400", color: "#D8D8D8" }}>© 2024 IQTAG. All rights reserved</Typography>
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: "32px" }}>
                  <Link href="https://drive.google.com/file/d/1vqxSqZEuH-9x94fYllw3ZFchjI-5_uB_/view?usp=drive_link" sx={styles.link} >
                    Terms of Use
                  </Link>

                  <Link href="https://drive.google.com/file/d/17jLxRIJ8dSEG8JEvt32RSN-hPiopELcj/view?usp=drive_link" sx={styles.link}>
                    Privacy Policy
                  </Link>

                  <Box sx={{ display: "flex", flexDirection: "row", }}>
                    {icon_white.map((social, index) =>
                      <Link
                        key={index}
                        sx={{
                          display: 'inline-flex',
                          padding: '0 8px',
                        }}
                        href={social.link}
                      >
                        {social.tag}
                      </Link>
                    )}
                  </Box>
                </Box>
              </Box >
            </Box>

          </Box>
        ) : (
          <>
            {
              screenWidth > 624
                ? (
                  <Box sx={styles.not_auth_footer} >

                    <LabelUI text="© 2024 IQTAG. All rights reserved" />
                    <Box sx={styles.not_auth_footer_links}>
                      <Link href="https://drive.google.com/file/d/1vqxSqZEuH-9x94fYllw3ZFchjI-5_uB_/view?usp=drive_link" sx={styles.not_auth_link} >
                        Terms of Use
                      </Link>

                      <Link href="https://drive.google.com/file/d/17jLxRIJ8dSEG8JEvt32RSN-hPiopELcj/view?usp=drive_link" sx={styles.not_auth_link}>
                        Privacy Policy
                      </Link>

                      <Box sx={{ display: "flex", flexDirection: "row", }}>
                        {icons.map((social, index) =>
                          <Link
                            key={index}
                            sx={{
                              display: 'inline-flex',
                              padding: '0 8px',
                            }}
                            href={social.link}
                          >
                            {social.tag}
                          </Link>
                        )}
                      </Box>
                    </Box>
                  </Box >
                )
                : (
                  <Box sx={styles.not_auth_footer} >

                    <Box sx={styles.not_auth_footer_links}>
                      <Box sx={styles.not_auth_footer_underline_links}>
                        <Link href="https://drive.google.com/file/d/1vqxSqZEuH-9x94fYllw3ZFchjI-5_uB_/view?usp=drive_link" sx={styles.not_auth_link} >
                          Terms of Use
                        </Link>

                        <Link href="https://drive.google.com/file/d/17jLxRIJ8dSEG8JEvt32RSN-hPiopELcj/view?usp=drive_link" sx={styles.not_auth_link}>
                          Privacy Policy
                        </Link>
                      </Box>

                      <Box sx={styles.not_auth_icons}>
                        {icons.map((social, index) =>
                            <Link
                                key={index}
                                sx={{
                                  display: 'inline-flex',
                                  padding: '0 8px',
                                }}
                                href={social.link}
                            >
                              {social.tag}
                            </Link>
                        )}
                      </Box>
                    </Box>
                    <LabelUI text="© 2024 IQTAG. All rights reserved" />
                  </Box >
                )
            }

          </>
        )
      }
    </>
  );
};

export default Footer;