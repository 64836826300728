import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import Products from "../../api/repositories/products";
import {handleApiError} from "../../helpers/handleApiError";

const initialState = {
  customField: {},
  isLoading: false,
};

export const addCustomField = createAsyncThunk(
    "products/addCustomField",
    async (payload) => {
      try {
        const response = await Products.add_custom_field({
          product_id: payload.productId,
          name : payload.title,
          index: payload.index,
          type : payload.type,
          value: payload.description
        });

        return response.data;
      } catch (error) {
        handleApiError(error)
      }
    },
);
export const updateCustomField = createAsyncThunk(
    "products/updateCustomField",
    async (payload) => {
      try {
        const response = await Products.update_custom_field(payload);

        return response.data;
      } catch (error) {
        handleApiError(error)
      }
    },
);
export const deleteCustomField = createAsyncThunk(
    "products/deleteCustomField",
    async (payload) => {
      try {
        const response = await Products.delete_custom_field(payload);
        return response.data;
      } catch (error) {
        handleApiError(error)
      }
    },
);
export const reorderCustomField = createAsyncThunk(
    "products/reorderCustomField",
    async (payload) => {
      try {
        const response = await Products.reorder_custom_field(payload);
        return response.data;
      } catch (error) {
        handleApiError(error)
      }
    },
);

const customFieldSlice = createSlice({
  name: "customField",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
        .addCase(addCustomField.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(addCustomField.fulfilled, (state) => {
          state.isLoading = false;
        });
    builder
        .addCase(updateCustomField.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(updateCustomField.fulfilled, (state) => {
          state.isLoading = false;
        });
    builder
        .addCase(deleteCustomField.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(deleteCustomField.fulfilled, (state) => {
          state.isLoading = false;
        });
    builder
        .addCase(reorderCustomField.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(reorderCustomField.fulfilled, (state) => {
          state.isLoading = false;
        });

  },
});

export default customFieldSlice.reducer;