import { Box, FormControl, InputBase } from '@mui/material'
import { useState } from 'react'
import IconUI from './icon'

interface Props {
  icon?: any,
  styleIcon?: object,
  searchValue: string
  handleSearch: (value: string) => void
}

const SearchUI = ({ icon, styleIcon, searchValue, handleSearch }: Props) => {
  return (
    <FormControl sx={{ boxShadow: '0 0 8px rgba(0, 0, 0, 0.2)', borderRadius: '48px' }}>
      {/* TODO: Посмотреть тень */}
      <InputBase
        sx={{
          border: 'none',
          backgroundColor: '#FFF',
          borderRadius: 'inherit',
          padding: '8px 16px',
          position: 'relative',
          width: '264px'
        }}
        onChange={(e) => handleSearch(e.target.value)}
        value={searchValue}
        placeholder="Search"
      />
      <IconUI
        name={searchValue ? 'cross' : 'search'}
        direction="right"
        onClick={searchValue ? () => handleSearch('') : undefined}
        style={styleIcon}
      />
    </FormControl>
  )
}

export default SearchUI