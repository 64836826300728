import { lazy } from "react";
import { createBrowserRouter, Navigate } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";

const Main = lazy(() => import("../pages/Main"));
const Login = lazy(() => import("../pages/Login"));
const Register = lazy(() => import("../pages/Register"));
const Recovery = lazy(() => import("../pages/Recovery"));
const Dashboard = lazy(() => import("../pages/Dashboard"));
const Company = lazy(() => import("../pages/Company"));
const Products = lazy(() => import("../pages/Products"));
const Bills = lazy(() => import("../pages/Bills"));
const Referal = lazy(() => import("../pages/Referal"));
const Profile = lazy(() => import("../pages/Profile"));
const TopUp = lazy(() => import("../pages/TopUp"));
const ProductsDetail = lazy(() => import("../pages/ProductDetail"));
const Notifications = lazy(() => import("../pages/Notification"));
const Verification = lazy(() => import("../pages/Verification"));
const VerificationRecovery = lazy(() => import("../pages/VerificationRecovery"));
const QrPreview = lazy(() => import("../pages/QrPreview"));

const routes = [
  // TODO: поменять роуты, когда добавится авторизация
  {
    path: "/home",
    element: (
        <ProtectedRoute>
          <Main />
        </ProtectedRoute>
    ),
    children: [
      {
        path: '/home/dashboard',
        element: <Dashboard />
      },
      {
        path: '/home/products',
        element: <Products />
      },
      {
        path: '/home/company',
        element: <Company />
      },
      {
        path: '/home/bills',
        element: <Bills />
      },
      {
        path: '/home/referal',
        element: <Referal />
      },
      {
        path: '/home/profile/password',
        element: <Profile />
      },
      {
        path: '/home/profile',
        element: <Profile />
      },
      {
        path: '/home/top-up',
        element: <TopUp />
      },
      {
        path: '/home/products/:id',
        element: <ProductsDetail />
      },
      {
        path: '/home/notifications',
        element: <Notifications />
      }
    ]
  },
  {
    path: "/",
    element: <Navigate to="/sign-in" />,
  },
  {
    path: "/sign-in",
    element: <Login />,
  },
  {
    path: "/sign-up",
    element: <Register />,
  },
  {
    path: "/verification/sign-up",
    element: <Verification />,
  },
  {
    path: "/verification/recovery",
    element: <VerificationRecovery />,
  },
  {
    path: "/recovery",
    element: <Recovery />,
  },
  {
    path: "/qr-preview/:id",
    element: <QrPreview/>
  }
]

const router = createBrowserRouter(routes);

export default router;
