import React from "react";
import './style.css'

interface Props {
  name?: string
  size?: number
  color?: string
  direction?: string
  onClick?: (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => void,
  style?: object | null,
  animate?: boolean,
  placeholder?: boolean
}

const IconUI = ({ name, size = 24, color = 'black', onClick, direction = 'default',style, animate, placeholder}: Props) => {

  return (
    <img
      onClick={onClick}
      style={{
        width: size,
        height: size,
        cursor: 'pointer',
        position: direction === "default" ? 'static' : 'absolute',
        right: direction === 'right' ? "16px" : "initial",
        left: direction === 'left' ? "0" : "initial",
        top: '1px',
        ...style,
      }}
      className={animate ? 'mini_loader' : ''}
      src={placeholder ? '/assets/placeholder.svg' :`/assets/icons/${name}.svg`}
      alt={''}
    />
  );
};

export default IconUI;
