import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {handleApiError} from "../../helpers/handleApiError";
import Payment from "../../api/repositories/payment";

const initialState = {
  paymentsLink: []
};

export const buyStarterPack = createAsyncThunk(
    "pay/buyStarterPack",
    async (payload) => {
      try{
        const response = await Payment.buy_starter_pack(payload)
        return response.data;
      } catch (error){
        handleApiError(error)
      }
    }
)
export const buyGrowthPack = createAsyncThunk(
    "pay/buyGrowthPack",
    async (payload) => {
      try{
        const response = await Payment.buy_growth_pack(payload)
        return response.data;
      } catch (error){
        handleApiError(error)
      }
    }
)
export const buyEnterprisePack = createAsyncThunk(
    "pay/buyEnterprisePack",
    async (payload) => {
      try{
        const response = await Payment.buy_enterprise_pack(payload)
        return response.data;
      } catch (error){
        handleApiError(error)
      }
    }
)

const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
        .addCase(buyStarterPack.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(buyStarterPack.fulfilled, (state,{payload}) => {
          state.isLoading = false;
          state.paymentsLink[0] = payload.Event
        })
    builder
        .addCase(buyGrowthPack.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(buyGrowthPack.fulfilled, (state,{payload}) => {
          state.isLoading = false;
          state.paymentsLink[1] = payload.Event
        })
    builder
        .addCase(buyEnterprisePack.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(buyEnterprisePack.fulfilled, (state,{payload}) => {
          state.isLoading = false;
          state.paymentsLink[2] = payload.Event
        })
  },
});

export default paymentSlice.reducer;
