import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import Products from "../../api/repositories/products";
import {toast} from "react-toastify";
import {handleApiError} from "../../helpers/handleApiError";

const initialState = {
  image: '',
  isLoading: false,
};

export const addPhoto = createAsyncThunk(
    "products/addPhoto",
      async (payload) => {
        try {
          const data = new FormData();
          data.append('product_id', payload.productId)
          data.append('index', Number(payload.index))
          data.append('file', payload.file)

          const response = await Products.add_photo_product(data);
          return response.data;

      } catch (error) {
        handleApiError(error)
      }
    },
);

export const deletePhoto = createAsyncThunk(
    "products/deletePhoto",
    async (payload) => {
      try {
        const response = await Products.delete_photo_product({
          product_id: payload.productId,
          index: payload.index
        });
        return response.data;

      } catch (error) {
        handleApiError(error)
      }
    },
);


const addPhotoSlice = createSlice({
  name: "photo",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
        .addCase(addPhoto.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(addPhoto.fulfilled, (state, { payload }) => {
          state.isLoading = false;
          state.image = payload;
        })
        .addCase(addPhoto.rejected, (state) => {
            state.isLoading = false;
        });
    builder
        .addCase(deletePhoto.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(deletePhoto.fulfilled, (state) => {
          state.isLoading = false
        });
  },
});

export default addPhotoSlice.reducer;