import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  analyticsTime: 30,
  analyticsView: 'Graph'
};

const analyticsSlice = createSlice({
  name: "analytics",
  initialState,
  reducers: {
    setAnalyticsView: (state, { payload }) => {
      state.analyticsView = payload
    },
    setAnalyticsTime: (state, { payload }) => {
      state.analyticsTime = payload
    }
  },
});
export const { setAnalyticsView, setAnalyticsTime } = analyticsSlice.actions

export default analyticsSlice.reducer;
