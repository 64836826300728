import { Box, Typography } from "@mui/material"
import styles from "./styles"
import { ButtonUI } from "../../../ui"
import {useDispatch} from "react-redux";
import {deleteCustomField} from "../../../../app/slices/field";
import {getProduct} from "../../../../app/slices/products";
import React from "react";

interface Props {
  openModal: boolean,
  setOpenModal: (openModal: boolean) => void,
  fieldIndex: number,
  productId: string | undefined,
  setUpdateFields: React.Dispatch<React.SetStateAction<boolean>>
}
const DeleteField = ({ openModal, setOpenModal, fieldIndex,productId, setUpdateFields}: Props) => {
  const dispatch = useDispatch();

  const submitDeleteField = async () => {
    try {
      // @ts-ignore
      await dispatch(deleteCustomField({product_id : productId, index: fieldIndex}))
          .finally(() => {
            // @ts-ignore
            dispatch(getProduct(productId))
            setUpdateFields(true)
          })
    } catch(error){
      console.log(error)
    }

  }

  return (
      <Box
        sx={styles.container}
        onClick={(e) => {e.stopPropagation()}}
      >

        <Box sx={styles.header}>
          <Typography variant="h6">Delete field</Typography>
          <img src='/assets/icons/modal_close.svg' onClick={() => setOpenModal(false)} alt={''}/>
        </Box>

        <Box sx={{ display: "flex",width:'100%',height: '100%', justifyContent:'center',alignItems:'center'}}>
         <Typography
             sx={{
               fontSize: '3rem',
               color: '#5F5F5F'
             }}
         >
           Delete?
         </Typography>
        </Box>

        <Box sx={styles.buttons}>
          <ButtonUI
            type="outline"
            text="Cancel"
            onClick={() => setOpenModal(false)}
            width="123px"
          />

          <ButtonUI
            type="default"
            text="Delete"
            onClick={() => {
              submitDeleteField()
              setOpenModal(false)
            }}
            width="123px"
          />
        </Box>
      </Box>
  )
}

export default DeleteField