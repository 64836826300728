import { Box } from "@mui/material"

import Header from "../Header"
import Footer from "../Footer"
import './styles.css'

const Loader = () => {
  return (
    <Box
      sx={{
        height: '100vh',
        width: '100wv',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        boxSizing: 'border-box',
      }}>
      <header>
        <Header isLangChange />
      </header>
      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div className="lds-spinner">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </Box>
      <footer><Footer /></footer>
    </Box >
  )
}

export default Loader