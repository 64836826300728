import axios from "axios";

const url = process.env.REACT_APP_BASE_URL_EU;

let refreshing = false;

const repeatInterval = () =>
  new Promise((resolve) => {
    const interval = setInterval(() => {
      if (!refreshing) {
        resolve();
        clearInterval(interval);
      }
    }, 100);
  });

class Service {
  static instance;
  
  constructor() {
    this._client = axios.create({
      baseURL: `${url}/api`,
    });

    this.resolvedInterceptor = this.resolvedInterceptor.bind(this);
    this.rejectedInterceptor = this.rejectedInterceptor.bind(this);

    this._client.interceptors.response.use(
      this.resolvedInterceptor,
      this.rejectedInterceptor
    );

    this._client.interceptors.request.use((config) => {
      const token = localStorage.getItem("access_token");
      // TODO исправить логику с проверкой на null
      if (token !== null) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    });
    Service.instance = this;
  }

  setBearer = token => {
    this._client.defaults.headers.common.Authorization = `Bearer ${token}`;
  };

  removeBearer = () => {
    delete this._client.defaults.headers.common.Authorization;
  };

  clearLocalStorage = () => {
    localStorage.removeItem('access_token');
  }

  setLocalStorage = (data) => {
    localStorage.setItem('access_token', data.token);
  }

  resolvedInterceptor(response) {
    return Promise.resolve(response);
  }

  async rejectedInterceptor(error) {
    const { status, config, data } = error.response;
    switch (status) {
      case 401:
      case 500:
      case 403:
      case 404:
      case 429:
      case 413:
        console.log('error', data)
        break;
      default:
        break;
    }
    return Promise.reject(error);
  }

  static getInstance() {
    if (Service.instance) {
      return Service.instance;
    }
    return new Service();
  }

  repeatRequest = async (originalRequest) => {
    const token = localStorage.getItem("access_token");
    originalRequest.headers.Authorization = `Bearer ${token}`;
    return new Promise((resolve, reject) => {
      this._client(originalRequest)
        .then((data) => resolve(data))
        .catch((error) => reject(error));
    });
  }
}

export default Service.getInstance()._client;
