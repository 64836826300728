import { Box, Typography } from "@mui/material"
import styles from "./styles"
import { ButtonUI, InputUI } from "../../../ui"
import {Form, Formik } from "formik"
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {updateCustomField} from "../../../../app/slices/field";
import {getProduct} from "../../../../app/slices/products";

interface Props {
  openModal: boolean,
  setOpenModal: (openModal: boolean) => void,
  fieldIndex: number ,
  productId: string | undefined,
  setUpdateFields: React.Dispatch<React.SetStateAction<boolean>>
}
const EditField = ({ openModal, setOpenModal, fieldIndex,productId, setUpdateFields}: Props) => {
  const dispatch = useDispatch();
  const customFields = useSelector((state:any) => state.products.productData.custom_fields || [])

  const submitHandler = async (values: any) => {
    try {
      // @ts-ignore
      await dispatch(updateCustomField({
                product_id: productId,
                index: fieldIndex,
                name : values.title,
                value: values.content
              }))
          .finally(() => {
            // @ts-ignore
            dispatch(getProduct(productId))
            setUpdateFields(true)
          })
    } catch(error){
      console.log(error)
    }
  }

  return (
    <Formik
        initialValues = {{
          title: customFields[fieldIndex].name,
          content: customFields[fieldIndex].value,
        }}
        onSubmit={submitHandler}
    >
      {({ values, handleSubmit, setFieldValue }) => (
          <Form>
            <Box
              sx={styles.container}
              onClick={(e) => {e.stopPropagation()}}
            >

              <Box sx={styles.header}>
                <Typography variant="h6">Edit field</Typography>
                <img src='/assets/icons/modal_close.svg' onClick={() => setOpenModal(false)} alt={''}
                     style={{cursor: 'pointer'}}
                />
              </Box>

              <Box sx={{ display: "flex", padding: "0 32px" }}>
                <InputUI label="Title" value={values.title} onChange={
                  (event: React.ChangeEvent<HTMLInputElement>) =>
                      setFieldValue('title',event.target.value)}/>
              </Box>

              <Box sx={{ display: "flex", padding: "0 32px" }}>
                <InputUI label="Content" value={values.content} onChange={
                  (event: React.ChangeEvent<HTMLInputElement>) =>
                      setFieldValue('content',event.target.value)}/>
              </Box>

              <Box sx={styles.buttons}>
                <ButtonUI
                  type="outline"
                  text="Cancel"
                  onClick={() => setOpenModal(false)}
                  width="123px"
                />

                <ButtonUI
                  type="default"
                  text="Edit"
                  onClick={() => {
                    handleSubmit();
                    setOpenModal(false)
                  }}
                  width="123px"
                />
              </Box>

            </Box>
          </Form>
      )}
    </Formik>
  )
}

export default EditField