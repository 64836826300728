import { toast } from 'react-toastify';
import {AxiosError} from "axios";

interface ApiError {
    status: number;
    data?: {
      message?: string;
    };
}
export const handleApiError = (error: ApiError) => {
  switch (error.status) {
    case 400: toast.error("Invalid request. Please check your data."); break;
    case 401: toast.error("Authorization error. Please log in."); break;
    case 403: toast.error("Access denied. You do not have permission."); break;
    case 404: toast.error("Resource not found."); break;
    case 500: toast.error("Internal server error. Please try again later."); break;
    case 502: toast.error("Gateway error. Server issues."); break;
    case 503: toast.error("Server temporarily unavailable. Please try again later."); break;
    default: toast.error(`An unknown error occurred. ${error}`);
  }
};
export const handleApiErrorSaveProduct = (error: any) => {
  if(error.response.data.detail.Message)
    toast.error("You need to add photo for save product.");
}