import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {handleApiError} from "../../helpers/handleApiError";
import Profile from "../../api/repositories/profile";
import {toast} from "react-toastify";

const initialState = {
  isLoading: false,
};

export const updatePassword = createAsyncThunk(
    "profile/updatePassword",

    async (payload) => {
      try {
        const response = await Profile.update_password(payload);
        toast.success('Password updated successfully')
        return response.data;
      } catch (error) {
        handleApiError(error)
      }
    },
);
export const uploadAvatar = createAsyncThunk(
    "profile/uploadAvatar",

    async (payload) => {
      try {
        console.log(payload)
        const response = await Profile.upload_avatar(payload);
        toast.success('Avatar upload successfully')
        return response.data;
      } catch (error) {
        handleApiError(error)
      }
    },
);

export const updateData = createAsyncThunk(
    "profile/updateData",

    async (payload) => {
      try {
        const response = await Profile.update_data(payload);

        toast.success('Data updated successfully')
        return response.data;
      } catch (error) {
        handleApiError(error)
      }
    },
);


const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
        .addCase(uploadAvatar.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(uploadAvatar.fulfilled, (state) => {
          state.isLoading = false;
        });
    builder
        .addCase(updateData.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(updateData.fulfilled, (state) => {
          state.isLoading = false;
        });
    builder
        .addCase(updatePassword.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(updatePassword.fulfilled, (state) => {
          state.isLoading = false;
        });
  },
});

export default profileSlice.reducer;
