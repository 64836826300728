import { createSlice, createAsyncThunk, isRejected } from "@reduxjs/toolkit";
import Dashboard from "../../api/repositories/dashboard";
import Kyb from "../../api/repositories/kyb";
import { handleApiError } from "../../helpers/handleApiError";
import {toast} from "react-toastify";

const initialState = {
  isLoading: false,
  kyb_status: "",
  adress_match: false,
  logo_company: '',
  isRejected: false,
  step_1: {
    company_name: '',
    date_of_incorp: '',
    company_reg_number: '',
    companys_website: '',
    employees_have: '',
    business_description: '',
  },
  step_2: {
    email: '',
    phone: '+44',
    primary_contact_name: '',
    position: '',
    code: 'UK',
  },
  step_3: {
    country: '',
    address1: '',
    address2: '',
    city: '',
    region: '',
    zip: '',
    a_country: '',
    a_address1: '',
    a_address2: '',
    a_city: '',
    a_region: '',
    a_zip: '',
  },

  step_4: {
    file1: null,
    file2: null,
    file3: null,
  }
}

export const setKybStatus = createAsyncThunk(
  'kyb/setKybStatus',
  async () => {
    try {
      const response = await Kyb.get_kyb_status()
      return response.data
    } catch (error) {
      return error
    }
  }
)

export const kybUploadLogo = createAsyncThunk(
    'kyb/kybUploadLogo',
    async (payload) => {
      try {
        const data = new FormData();
        data.append('file', payload)
        const response = await Kyb.upload_logo(data)
        return response.data
      } catch (error) {
        return error
      }
    }
)

export const kybDeleteLogo = createAsyncThunk(
    'kyb/kybDeleteLogo',
    async () => {
      try {
        const response = await Kyb.delete_logo()
        return response.data
      } catch (error) {
        return error
      }
    }
)

export const setKyb_1 = createAsyncThunk(
  'kyb/step_1',
  async (payload) => {
    try {
      const response = await Kyb.company_data(payload)
      return response.data
    } catch (error) {
      return error
    }
  }
)

export const setKyb_2 = createAsyncThunk(
  'kyb/step_2',
  async (payload) => {
    try {
      const response = await Kyb.identity_data(payload)
      return response.data
    } catch (error) {
      return error
    }
  }
)

export const setKyb_3 = createAsyncThunk(
  'kyb/step_3',
  async (payload) => {
    try {
      const response = await Kyb.address_data(payload)
      return response.data
    } catch (error) {
      return error
    }
  }
)


// TODO: уточнить, в каком формате должны отправляться файлы
export const setKyb_4 = createAsyncThunk(
  'kyb/step_4',
  async (payload) => {
    try {
      const response = await Kyb.upload_pdfs(payload)
      return response.data
    } catch (error) {
      return error
    }
  }
)

const kybSlice = createSlice({
  name: "kyb",
  initialState,
  reducers: {
    setStatus: (state, action) => {
      state.kyb_status = action.payload
    },
    setLogo: (state, action) => {
      state.logo_company = action.payload
    },
    setAdressMatch: (state, action) => {
      state.adress_match = action.payload
    },
    setIsRejected: (state, action) => {
      state.isRejected = action.payload
    },
  },
  extraReducers: (builder) => {

    builder
      .addCase(setKybStatus.pending, (state) => {
        state.isLoading = true
      })
      .addCase(setKybStatus.fulfilled, (state, { payload }) => {
        state.isLoading = false
        state.kyb_status = payload.KYB_Status.kyb_status
        state.logo_company = payload.KYB_Status.logo_company
      })

    builder
      .addCase(setKyb_1.pending, (state) => {
        state.isLoading = true
      })
      .addCase(setKyb_1.fulfilled, (state, { meta }) => {
        state.isLoading = false
        state.step_1 = meta.arg
      })

    builder
      .addCase(setKyb_2.pending, (state) => {
        state.isLoading = true
      })
      .addCase(setKyb_2.fulfilled, (state, { meta }) => {
        state.isLoading = false
        state.step_2 = meta.arg
      })

    builder
      .addCase(setKyb_3.pending, (state) => {
        state.isLoading = true
      })
      .addCase(setKyb_3.fulfilled, (state, { meta }) => {
        state.isLoading = false
        state.step_3 = meta.arg
      })
  }
})
export const { setStatus, setAdressMatch,setLogo,setIsRejected } = kybSlice.actions
export default kybSlice.reducer
